<!--
 * @Author: a3802 253092329@qq.com
 * @Date: 2024-08-23 20:16:20
 * @LastEditors: a3802 253092329@qq.com
 * @LastEditTime: 2024-12-11 01:55:55
 * @FilePath: \duanju\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
        <div class="loading" v-if="loading">
            <van-loading  type="circular" color="#fff" size="50px"/>       
        </div>  
            <div class="content">

                  	<div class="item_dj">
                      	<img src="https://image.hrzhuolin.com/static/png/bg_lottery-BmNilY4c.png" class="bg_img">
						<div ref="videoContainer" class="video-container">
							<div class="video" v-if="showvideo">
								<video  ref="videoPlayer" src="https://djtencentcdn.yueyueyouqian.cn/uploads/20240902/lihunhounvwang1.mp4" poster="https://djtencentcdn.yueyueyouqian.cn/uploads/20240902/5858ae46f2cc057d9a96a12cdfe8083d.jpg" x5-video-player-type="h5-page" controls></video>
							</div>
							<div class="btn item_link_dj">
								<a href="https://mindjvideo.yueyueyouqian.cn/#/?sn=XietcieTcMCtt">继续观看精彩剧情</a>
							</div>
						</div>
						<div class="lucky_around">
							<div class="lucky_game">
								<LuckyWheel ref="myLucky" width="300px" height="300px" :prizes="prizes" :blocks="blocks" :buttons="buttons" :default-config="defaultConfig" @start="startCallback" @end="endCallback" @click="handleClick" />
							</div>
							<div class="btn item_link_around">
								<a href="javascript:void(0)" >当前剩余抽奖次数{{ num }}次</a>
							</div>
						</div>
						<div class="item_service">
							<router-link :to="{ path: '/refund', query: { sn: orderId } }"  active-class="nav-color">对服务不满意?快捷售后</router-link>
						</div>
                
                  </div>
					<teleport to="body">
						<van-form @submit="onSubmit">
							<div v-if="showModal" class="modal-overlay">
								<div class="game-turn turn">
									<div  class="van-config-provider" style="--van-dialog-background: red;">
										<div class="van-overlay" role="button" tabindex="0" style="z-index: 2001;"><!----></div>
										<div role="dialog" tabindex="0" class="van-popup van-popup--center van-dialog" style="z-index: 2001; background-color: rgba(255, 255, 255, 0);"><!---->
											<div>
												<div  class="getbox flex flex-row justify-center items-center">
													<img  alt="" class="prize" src="https://image.hrzhuolin.com/static/png/j_3q-DJBqY5g_.png" style="margin: 186px 116px 16px 116px;width: 90px;height: 103px; transform: scale(0.997439) translateZ(0px);">
													<div class="form">
														<van-cell-group>
															<van-field v-model="phoneNumber" clearable center type="number"  placeholder="请输入支付宝手机号" placeholder-class="pla" class="ipt" maxlength="11"/>
														</van-cell-group>
														<van-cell-group>
															<van-field v-model="captcha" center clearable  type="number" placeholder="请输入短信验证码" class="ipt" placeholder-class="pla" maxlength="6">
																<template #button>
																<!-- <div style="margin-top: 16px;">   -->
																	<van-button :disabled="isDisabled"  size="small" type="primary" style="color:#cfd3db; background: #1989fa0d;border: solid #1989fa00; border-radius: 4.8vmin;" @click="sendCode" block>{{ isDisabled ? `${remainingTime }s` : '获取验证码' }}</van-button>
																<!-- </div> -->
																</template>
															</van-field>
														</van-cell-group>
													</div>
													<div  class="handel flex flex-row justify-center items-center">
														<button class="btn flex flex-row justify-center items-center" type="submit">领取奖励</button>
														<div class="btn flex flex-row justify-center items-center dju" @click="btn_close">放弃奖励 下载观看短剧</div>
														<!-- <img alt="放弃奖励 下载观看短剧 " src="https://image.hrzhuolin.com/static/png/btn_dju-7Xs5ZyTB.png" style="width:100%"> -->
														<div class="text pb-3"> 点击领取或放弃奖励表示已阅读并同意 <text  >《活动规则》</text></div>
													</div>
												</div>
											</div>
											<div class="van-hairline--top van-dialog__footer">
											<!----><!---->
											</div>
										<!---->
										</div>

									</div>
								</div>
							</div>
						</van-form>
					</teleport>
					<teleport to="body"> 
						<!-- <van-form @submit="onSubmit"> -->
						<div v-if="showSuccess" class="modal-overlay"> <!-- 点击遮罩层关闭模态框 -->
							<div class="zjbox">
								<div class="tip">
									如已正确填写领取信息，生活权益大礼包到账时效为领奖后1个工作日内下发到手机号对应账号。如有疑问请联系在线客服
								</div>
								<div class="btn item_link_dj">
									<a href="https://mindjvideo.yueyueyouqian.cn/#/?sn=XietcieTcMCtt">继续观看精彩剧情</a>
								</div>
							</div> <!-- 阻止事件冒泡 -->
						</div>
						<!-- </van-form> -->
					</teleport>
					<teleport to="body">
						<div v-if="showPay" class="custom-popup modal-overlay">
							<div class="order_tips">
								<div class="tips_title">当前订单没有支付,无法参与抽奖</div>
								<van-button type="primary" class="order_btn" @click=btnPay>立即支付</van-button>
							</div>
						</div>
					</teleport>    
            </div>
            <div class="rightInfo">
              	<router-link :to="{ path: '/refund', query: { sn: orderId } }" class="nav-item" active-class="nav-color">自助退款</router-link>
              	<a id="53kfbtn" class="kefu" href="https://tb.53kf.com/code/client/818133c55cba154d3d56d99366e4b4475/1">在线客服</a>
            </div>
			<div class="leftInfo">
				<router-link :to="{ path: '/rule', query: { sn: orderId } }" class="nav-rule" active-class="nav-color">规则</router-link>
			</div>

			<div  class="toast tos" style="display: none;">
				<div></div>
			</div>
</template>

<script>
import { reactive,ref,onMounted } from 'vue';
import { Toast,Loading,Popup} from 'vant';
import * as Verify from '../../api/verify';
import * as Index from '../../api/index';
import 'video.js/dist/video-js.css'
import { LuckyWheel } from '@lucky-canvas/vue'

export default {
	components:{
		LuckyWheel,
		Popup,
		"van-loading":Loading
	},


    setup() {


		onMounted(() => {
			luckyWheel()
		})

		        // 取url中的参数值
        const getQuery = (name) => {
            let geturl = window.location.href
            let getqyinfo = geturl.split('?')[1]
            let params = new URLSearchParams('?' + getqyinfo);
            return params.get(name);
        };

		const order = reactive({ 
                form: {
                    order_id: ''                    
                }
        });

		const showvideo = ref(true);

		const orderId = getQuery('sn');

		const loading = ref(false);
		const myLucky = ref();
		const num = ref();
		const prizes = ref([
			{
				background: '#fff6e5',
				imgs: [{src:'https://image.hrzhuolin.com/static/png/j_288-Bgj-oVEU.png',width:'40%',top:'30%'}], // 修改为你的图片地址
			},
			{
				background: '#fff6e5',
				imgs: [{src:'https://image.hrzhuolin.com/static/png/j_888-B0T46elS.png',width:'45%',top:'30%'}], // 修改为你的图片地址
			},																				
			{
				background: '#fff6e5',
				imgs: [{src:'https://image.hrzhuolin.com/static/png/j_88-Ba6rz7S-.png',width:'40%',top:'30%'}], // 修改为你的图片地址
			},																				
			{
				background: '#fff6e5',
				imgs: [{src:'https://image.hrzhuolin.com/static/png/j_188-C0ToLHpj.png',width:'40%',top:'30%'}], // 修改为你的图片地址
			},																				
			{
				background: '#fff6e5',
				imgs: [{src:'https://image.hrzhuolin.com/static/png/j_388-CuIdM6L4.png',width:'40%',top:'30%'}], // 修改为你的图片地址
			},																				
			{
				background: '#fff6e5',
				imgs: [{src:'https://image.hrzhuolin.com/static/png/j_3q-DJBqY5g_.png',width:'33%',top:'20%'}], // 修改为你的图片地址
			},																				
		]);
		const blocks = ref([
			{
				padding: '.35rem',
				imgs: [{
					src: 'https://image.hrzhuolin.com/static/png/bg_circle-BboiiWQc.png',
					width: '100%',
					height:'100%',
					// rotate: true
				}]
			}
		]);
		const buttons = ref([
			{
				radius: '45%',
				imgs: [{
					src: 'https://image.hrzhuolin.com/static/png/btn_turn-Dyvz0fqH.png',
					width: '60%',
					top: '-80%',

				}]
			}
		]);
		const defaultConfig = reactive(
			{
				gutter: '0.3px',
				speed: 1,
				// accelerationTime:2000,
				// decelerationTime:2000
			}
		);


		let id = ref(0)

		
		const showModal = ref(false);
		const showPay = ref(false);
		const showSuccess = ref(false);

		const phoneNumber = ref(''); // 手机号  
		const captcha = ref(''); // 验证码  
		const isDisabled = ref(false); // 验证码按钮是否禁用  
		const remainingTime = ref(0); // 验证码剩余时间  
		let timer = null; // 验证码倒计时定时器  

		const handleClick = () => {

			if(num.value == 0){
				Toast('没有抽奖次数了');
				return false;
			}

            order.form.order_id = orderId
            Index.getNum(order).
                then(result => {
					console.log(result);
                    if (result.status == 500) {
                        Toast(result.data.message);
                    } else {

						defaultConfig.speed = 3;

						const timer = setInterval(() => {
							id.value += 1
							// 这里==6可以写成动态长度，因为只是个deom没有细写
							id.value = id.value == 6 ? 0 : id.value
						}, 500)

						setTimeout(() => {
							let index = 5
							console.log('index----', index)
							// 然后停止游戏 (缓慢停止)
							myLucky.value.stop(index)
							//这个定时器是动态奖品显示，不加的话奖品出来了转盘还在转，根据自己需求改
							setTimeout(() => {
								id.value = index
								clearInterval(timer)
							}, 2000)

						}, 4000)							


												
                    }


                }) 


		}

		const startCallback = () => {
			return true;
		};

		const luckyWheel = () => {
			myLucky.value.play()
            order.form.order_id = getQuery('sn');
            Index.getNum(order).
				then(result => {
					if (result.status == 200) {
						// console.log(result);
						num.value = result.data.data;
						showPay.value = false;

					}else{
						showPay.value = true;
						document.body.classList.add('no-scroll');
					}
				})			
		};


		const endCallback = (prizes) => {
		// 当完全停止时, 触发回调函数
			// console.log(JSON.parse(JSON.stringify(prizes)))
			// console.log('我是获奖回调')

			order.form.order_id = getQuery('sn');

			Index.countNum(order).
                then(result => {
                    if (result.status == 500) {
						return false;
                    } else {
						// num.value = 0;
						// videoPlayer.value.currentTime = 0;

						showvideo.value = false;
						
						showModal.value = true;
						document.body.classList.add('no-scroll');
						
						// showSuccess.value = true;
						return true;				
                    }

                }) 			

		};

		// 发送验证码  
		const sendCode = () => {  
			// 验证手机号是否已输入  
			if (!phoneNumber.value) {  
				// 提示用户输入手机号  
				Toast('请先输入手机号！');
				return;  
			}


			if (!Verify.isMobile(phoneNumber.value)) {
				Toast('请输入正确格式的手机号')
				return;
            }
			
			if(isDisabled.value){
				return;
			}
			
			// 模拟发送验证码到手机（实际应用中应为API调用）  
			console.log('发送验证码到手机号：', phoneNumber.value); 

			
            Index.sendsms({'mobile':phoneNumber.value}).
                then(result => {
                    if (result.status == 500) {

						console.log(result);
                    } else {
						console.log(result);
                    }


                }) 			
			
			// 禁用按钮，开始倒计时  
			isDisabled.value = true;  
			remainingTime.value = 60;  
			
			// 清除之前可能存在的定时器  
			clearInterval(timer);  
			timer = setInterval(() => {  
				if (remainingTime.value <= 0) {  
					clearInterval(timer);  
					isDisabled.value = false;  
					remainingTime.value = 0;  
				} else {  
					remainingTime.value--;  
				}  
			}, 1000);  
		}; 


		// 验证手机号
		const validteData = (str) => {
			if (Verify.isEmpty(str)) {
				Toast('请输入手机号');
				return false
			}
			if (!Verify.isMobile(str)) {
				Toast('请输入正确格式的手机号')
				return false
			}			
			return true
		};


		const onSubmit = () => {
			// console.log(phoneNumber.value);
			// console.log(captcha.value);
		
			if(validteData(phoneNumber.value)){

				if (Verify.isEmpty(captcha.value)) {
					Toast('请输入验证码');
					return false

				}else{

					Index.getEquity({'mobile':phoneNumber.value,'smsCode':captcha.value,'order_id':orderId}).
						then(result => {
							if (result.status == 500) {

								console.log(result);
							} else {
								showModal.value = false;
								showvideo.value = true;
								showSuccess.value = true;
							}

					}) 	
				}
			}

		};


		const btn_close = () => {
			showModal.value = false;
			showvideo.value = true;
			document.body.classList.remove('no-scroll')
			// luckyWheel()
			myLucky.value.play()
		};


		const btnPay = () => {


			    Toast.loading({
                    message: '',
                    forbidClick: true,
                    overlay: true,
					iconSize: '45',
					duration: 5000
                });

			Index.btnPay({'order_id':orderId}).
				then(result => {
					if (result.status == 500) {

						console.log(result);
						Toast('网络错误');
					} else {


						console.log(result);
						loading.value = true;
						setTimeout(() => {
							loading.value = false;
							location.reload();
						}, 3000); // 假设2秒后关闭遮罩


						if (result.data.data.pay_chal == 'sum' || result.data.data.pay_chal == 'jxpay' || result.data.data.pay_chal == 'heepay') {

							// window.location.href = result.data.data.payment;//正常wx/支付宝

							window.location.href = 'https://tgqy.yueyueyouqian.cn/hpay.html?url=' + encodeURIComponent(result.data.data.payment);


						} else if ( result.data.data.pay_chal == 'gzpay' || result.data.data.pay_chal == 'sypay') {

							        window.location.href = 'https://tgqy.yueyueyouqian.cn/ds.html?url=' + btoa(result.data.data.payment);
                                    Toast.clear();

                                    return true;

						} else if (result.data.data.pay_chal == 'bill9') {

							window.location.href = 'https://tgqy.yueyueyouqian.cn/hpay.html?pay_string=' + encodeURIComponent(result.data.data.payment);//wx表单提交支付
							
						} else {
								window.location.href = 'weixin://app/?appId=wxc494aae6debcd84b&path=page/common/wbeview?url=' + encodeURIComponent(result.data.data.payment);
						}


					}

			}) 			
		}



		return {
			onSubmit,
			prizes,
			blocks,
			buttons,
			endCallback,
			startCallback,
			id,
			myLucky,
			defaultConfig,
			handleClick,
			getQuery,
			order,
			num,
			luckyWheel,
			phoneNumber,
			captcha,
			isDisabled,
			remainingTime,
			timer,
			sendCode,
			showModal,
			validteData,
			showSuccess,
			btn_close,
			orderId,
			showvideo,
			btnPay,
			showPay,
			loading


		};

	},

}


</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
</style>
